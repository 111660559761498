
import { defineComponent } from 'vue';
import {
    IonHeader,
    IonIcon,
    IonToolbar,
} from '@ionic/vue';


export default defineComponent({
    name: 'HeaderLoyalty',
    components: {
        IonHeader,
        IonIcon,
        IonToolbar,

    },    
});
