
import { defineComponent } from 'vue';
import {
    IonPage,
    IonItem,
    IonInput,
    IonButton,

} from '@ionic/vue';
import { StateCache } from '@/services/cache';
import HeaderLoyalty from '@/components/Header/HeaderLoyalty.vue';
import { ApiService } from '@/services/api';
import useConfig from '@/services/config';

const client = new ApiService();

export default defineComponent({
    name: 'LoyaltySignupGuest',
    components: {
        IonPage,
        IonItem,
        IonInput,
        IonButton,
        HeaderLoyalty,
    },
    data: ()=>({
                actionUrl: undefined as unknown as string,

        shopID: undefined as unknown as number,
        shopRes: undefined as unknown as ApiRes.ShopI,
        phone: undefined as unknown as string,
        errors: undefined as unknown as [],
    }),
    methods: {
        phoneFocus() {
            window.scrollTo(0, document.body.scrollHeight);
        },
        async loyaltySignupGuest($event: any) {
            let success = false;
            let errors = [];

            if(!this.phone) return;
            $event.target.blur(); // remove focus from 

            const params = { phone: this.phone };
            client.loyaltySignupGuest( this.shopID, `expo-loyalty-shop-${this.shopID}`, params )
            .then((res) => {
                success = res && res.success;
                this.phone = ''; // Clear input
                if(success) {
                    this.errors = [];
                   
                    this.$router.push({ path: '/loyalty/guest/confirmation', query: { 
                        'isNew' : res.data.isNew,
                        'offersDropped' : res.data.offersDropped,
                        'expired' : res.data.expired ? res.data.expired.length : 0, 
                        'success' : 'true',
                        } 
                    });
                }
            })
            .catch((e) => {
                success = e.response.data.success;
                const data = e.response.data.data;
                errors = data.errors ? data.errors.config : [];
                
                this.$router.push({ path: '/loyalty/guest/confirmation', query: { 
                        'success' : 'false',
                        'error' : errors
                    } 
                });
            });    
        }
    },
    async mounted() {
        const cache = new StateCache();
        this.shopID = await cache.get(cache.SHOP_ID);
                this.actionUrl = `${useConfig().baseUrl()}/handlers/phone-offers/expo-loyalty-shop-${this.shopID}`;

        this.shopRes = await client.getShop();
        this.errors = [];
    },
    
    computed: {
        perks(): string {
            if(!this.shopRes) return '';
            const shop_expo: ApiData.ShopI = this.shopRes.data;
            return shop_expo.expo.loyalty_title;
        },
        offer(): string {
            if(!this.shopRes) return '';

            const shop_expo: ApiData.ShopI = this.shopRes.data;
            return shop_expo.expo.loyalty_copy;
        }
    },    
});
